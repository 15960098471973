.contentActive {
  display: block;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  border: 1px solid black;
  width: fit-content;
  padding: 5px 15px;
  text-align: left;
  margin: 275px 0 275px 0;
}

.contentInactive {
  display: none;
}
