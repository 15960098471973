.container {
  background-color: black;
  margin-top: 7%;
  padding: 3rem 4rem 2rem;
}

.container h1 {
  color: white;
}

.container img {
  width: -webkit-fill-available;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: black;
  flex-direction: row;
}

.wrapper p {
  color: white;
  padding-top: 10px;
  padding-bottom: 40px;
  width: 35%;
}

.listWrapper {
  background-color: black;
}

.listWrapper li {
  font-size: 18px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: underline;
  text-decoration-color: white;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
  -webkit-text-stroke: 0.1px;
  font-weight: normal;
}
.listWrapper li:hover {
  color: white;
}

.list {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
