.container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 95%;
  margin: auto;
}

.nftGalleryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}
