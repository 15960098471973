.container {
  border: 1px solid black;
  height: 72vh;
  width: 23vw;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-left: 40px;
  padding-left: 15px;
  padding-right: 15px;
  row-gap: 20px;
}

.container::after {
  position: absolute;
  content: '';
  border: 1.5px solid greenyellow;
  transform: translate(-55px, 10px);
  z-index: -1;
  height: 72vh;
  width: 23vw;
  margin-left: 50px;
}

.accountContainer {
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 25vh;
  margin: 0px;
}

@media screen and (min-height: 950px) {
  .container {
    row-gap: 50px;
  }
}
