.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  text-align: center;
  padding-top: 5%;
  padding-bottom: 2%;
}

.container p {
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
}
