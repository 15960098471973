.outer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  z-index: 0;
  user-select: 'none';
}

.leftX {
  transform: translateX(500%);
  display: inline-flex;
  user-select: 'none';
}

.rightX {
  transform: translateX(-500%);
  display: inline-flex;
  user-select: 'none';
}
