.modalContainer {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: fixed;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.65);
}

.modalInner {
  width: 40%;
  height: max-content;
  background-color: white;
  border: 2.5px solid black;
  border-radius: 5px;
  margin: 7.5% auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 11;
}

.modalHeader {
  text-align: center;
}

.modalNFTName {
  font-size: 22px;
  text-decoration: underline;
  margin-bottom: 8px;
}

.modalCallToAction {
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
}

.modalNFTThumbnail {
  width: 35%;
  height: 40%;
  -webkit-animation: fadein 0.75s;
  -moz-animation: fadein 0.75s;
  -ms-animation: fadein 0.75s;
  -o-animation: fadein 0.75s;
  animation: fadein 0.75s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.salePriceContainer {
  display: flex;
  margin-bottom: 8px;
}

.balanceContainer {
  display: flex;
  margin-top: 8px;
}

.modalContentText {
  font-size: 18px;
}
.modalContentPriceText {
  font-size: 18px;
  border-bottom: 3px solid #adff00;
  font-weight: 800;
}

.price {
  border-bottom: 5px solid #adff00;
  transition: 0.3s;
}

.price:hover {
  color: #adff00;
  border-bottom: 5px solid black;
}

.sellPriceWrapper {
  border-bottom: 5px solid #adff00;
  display: flex;
  height: max-content;
  width: max-content;
  margin-top: 14px;
}

@keyframes blink {
  50% {
    border-color: #fefefe;
  }
}

.sellPriceInput {
  width: 125px;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-size: 38px;
  font-weight: 700;
  line-height: -50%;
  text-align: right;
}

.buttonContainer {
  width: 75%;
  height: max-content;
  margin: 0 0 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.rejectButton {
  position: relative;
  font-family: 'Poppins', 'sans-serif';
  color: black;
  background-color: white;
  font-weight: 700;
  font-size: 1.5rem;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  border: 2px solid black;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 2rem;
  padding: 8px 20px;
  right: 0px;
  bottom: 0px;
  transition: right 0.3s, bottom 0.3s;
}

.rejectButton:hover {
  bottom: 5px;
  right: 5px;
}

.rejectButton::after {
  position: absolute;
  content: ' ';
  z-index: -1;
  height: 93%;
  width: 98%;
  border: 2.5px solid #adff00;
  transform: translate3d(5%, 20%, 0px);
  top: 0px;
  left: 0px;
  transition: top 0.3s, left 0.3s;
}

.rejectButton:hover::after {
  top: 10px;
  left: 10px;
}
