.container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: black;
}

.container video {
  width: 100%;
  height: 100%;
}
