.container {
  background-color: black;
  display: flex;
  padding-bottom: 2%;
}
.container li {
  font-family: 'Poppins', normal;
  font-size: 18px;
  font-weight: 700;
  line-height: 31px;
  color: white;
  padding-top: 15%;
  text-transform: capitalize;
  -webkit-text-stroke: 0.1px;
}

.container ul:nth-child(1) {
  padding-left: 1%;
}

.container ul:nth-child(2) {
  padding-left: 75%;
}

.container li:hover {
  color: white;
}
