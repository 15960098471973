* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  cursor: pointer;
}

li {
  list-style-type: none;
}

h1 {
  font-size: 3rem;
  font-family: 'Kanit', sans-serif;
}

p {
  font-size: 1.1rem;
  font-family: 'Poppins', sans-serif;
}
