.container {
  padding-top: 3%;
  padding-bottom: 3%;
}
.marquee {
  overflow: hidden;
  width: 100%;
  height: 20%;
  background-color: black;
  white-space: nowrap;
  margin: 0 auto;
}

.marquee span {
  display: inline-block;
  font-family: 'Kanit', 'sans-serif';
  color: white;
  font-size: 5rem;
  line-height: 200%;
  letter-spacing: 400%;
  left: 100%;
  position: relative;
  animation: marquee 20s linear infinite;
}

.marquee:hover span {
  animation-play-state: paused;
}

.marquee span:nth-child(1) {
  animation-delay: -5.6s;
}
.marquee span:nth-child(2) {
  animation-delay: -4s;
}
.marquee span:nth-child(3) {
  animation-delay: -2.4s;
}
.marquee span:nth-child(4) {
  animation-delay: -0.8s;
}
.marquee span:nth-child(5) {
  animation-delay: 0.8s;
}
.marquee span:nth-child(6) {
  animation-delay: 2s;
}

.marquee svg {
  margin-left: 50px;
  margin-right: 50px;
}

@keyframes marquee {
  0% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}
