.tabs {
  font-family: 'Kanit', sans-serif;
  color: white;
  font-weight: 900;
  font-size: 50px;
  -webkit-text-stroke: 1px black;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;
  border: 0ch;
  background-color: white;
}

.tabsActive {
  font-family: 'Kanit', sans-serif;
  color: black;
  font-weight: 900;
  font-size: 50px;
  -webkit-text-stroke: 1px black;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;
  border: 0ch;
  background-color: white;
}
