.nftColumnContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.nftGalleryContainer {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
}

.outerContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
}
