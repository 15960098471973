.overlay {
  z-index: 2;
  position: relative;
  margin-bottom: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: end;
}

.individualNFT {
  display: flex;
  flex: 1;
  padding: 0;
}

.NFTContents {
  position: absolute;
  display: inline-block;
  height: 75%;
  width: 100%;
  z-index: 3;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}

.overlay:hover .NFTContents {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.8) 15%,
    rgba(0, 0, 0, 0.5) 40%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 1;
  transition: 0.35s;
}

.contentContainer {
  display: flex;
  width: 90%;
  height: max-content;
  margin-bottom: 10px;
}

.nftDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  flex: 1;
}

.nftMarketInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
}

.cardHeader {
  color: white;
  font-size: 18px;
  font-weight: 800;
}

.cardText {
  color: white;
  font-size: 12px;
  font-weight: 100;
  text-decoration: underline;
  cursor: pointer;
}

.nftButton {
  font-size: 1.5rem;
  font-family: 'Poppins', 'sans-serif';
  font-size: 16px;
  font-weight: 800;
  color: #000000;
  padding: 4px 12px 4px 12px;
  border-radius: 3px;
  border: none;
  background-color: #fefdfd;
  margin-top: 5px;
  margin-bottom: 2px;
  cursor: pointer;
}
