.overallContainer {
  align-items: center;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  height: 100vh;
  width: 100%;
  z-index: 0;
}

/*This is the styling problem area */
.overallContainer img:last-child {
  width: -webkit-fill-available;
  margin: 3rem 4rem 2rem;
}

.wrapper {
  width: 90vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #ffffff;
  justify-content: space-evenly;
}

.content {
  width: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: flex-start;
  text-align: left;
  z-index: 0;
}

.number {
  font-family: 'Kanit', 'sans-serif';
  color: black;
  font-size: 3rem;
  width: fit-content;
  position: relative;
  z-index: 1;
}

.number::after {
  -webkit-text-stroke: 1.5px greenyellow;
  position: absolute;
  content: attr(data-text);
  color: white;
  z-index: -1;
  top: 0.1rem;
  left: 0.2rem;
  margin-left: -7px;
  margin-top: 13px;
  transform: translate3d(10px, -10px, -1px);
}
