.container {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: fixed;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
}

.inner {
  position: relative;
  height: max-content;
  width: max-content;
  background: #fefefefe;
  animation-name: slide-right;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  transform: translateX(275px);
}

.nftEnlargeViewOverlay {
  display: flex;
  height: 500px;
  width: 500px;
  transform: translateX(-500px);
  animation-name: slide-left;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  justify-content: center;
  align-items: center;
  z-index: 12;
  background-color: white;
}

.nftEnlargeViewOverlay:hover .modalNFTThumbnail {
  filter: blur(3px) brightness(40%);
  cursor: pointer;
}

.nftEnlargeViewOverlay:hover .hoverContent {
  animation-name: fade-in;
  animation-fill-mode: forwards;
  animation-duration: 0.8s;
  cursor: pointer;
}

.hoverContent {
  color: white;
  position: absolute;
  opacity: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  pointer-events: auto;
}

.eyeIcon {
  width: 75px;
}

.modalNFTThumbnail {
  position: relative;
  z-index: -1;
  box-shadow: 5px 6px 15px 5px rgb(0 0 0 / 0.5);
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(275px);
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-500px);
  }
}

.nftDetails {
  position: absolute;
  transform: translatey(-500px);
  z-index: 10;
  width: 100%;
  height: 97%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: -1;
  align-items: center;
}

.nftHeader {
  text-align: center;
  font-size: 32px;
  font-weight: 200;
  text-decoration: underline;
  color: black;
  margin-top: 20px;
  font-family: 'Kanit', sans-serif;
}

.lineBreak {
  border: none;
  height: 3px;
  background-color: #adff00;
  width: 75%;
}

.contentDescription {
  width: 90%;
  font-size: 40px;
}

.contentSection {
  padding-left: 25px;
  padding-right: 25px;
  width: max-content;
}

.nftDetailsTable {
  width: 100%;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
}
