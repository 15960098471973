.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

h2.formStart {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  margin-bottom: 2%;
  margin-top: 3%;
}

p.formStart {
  margin-bottom: 3%;
}

.previewImageCircle {
  width: 250px;
  height: 250px;
  border: 5px solid black;
  border-radius: 50%;
  overflow: hidden;
}

img.previewImageCircle {
  object-fit: cover;
}

.uploadedImage {
  display: flex;
  flex-direction: row;
}

.nameDesc {
  display: flex;
  flex-direction: column;
}

.formContainer input,
textarea,
.attributeValue {
  border: none;
  width: 50vh;
  height: 35px;
  color: black;
  background-color: white;
  border: 1.5px solid black;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 5px;
  margin-right: 15px;
  position: relative;
  cursor: pointer;
  margin-bottom: 2%;
  margin-top: 5%;
}

.formContainer .description {
  height: 130px;
}

.formContainer .attribute {
  width: 19vh;
}

.formContainer .attributeValue {
  display: flex;
  align-items: center;
  border: 1px black solid;
  padding: 10px;
  background-color: black;
  color: white;
  font-size: 1.5rem;
}

.formContainer .attributeValue p {
  font-size: 1.5rem;
  margin-right: 2%;
}

.formContainer .select {
  border: none;
  width: 50vh;
  height: 45x;
  color: black;
  background-color: white;
  border: 1.5px solid black;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 5px;
  margin-right: 15px;
  position: relative;
  cursor: pointer;
  margin-top: 5%;
}

.attributeSection {
  display: flex;
  align-items: baseline;
  margin-top: -5%;
}

.formContainer .addButton {
  position: relative;
  font-family: 'Poppins', 'sans-serif';
  color: #ffffff;
  background-color: black;
  font-weight: 700;
  font-size: 1.5rem;
  width: fit-content;
  height: 35px;
  cursor: pointer;
  border: none;
  margin-right: 1rem;
  margin-top: 2rem;
  padding: 10px 15px;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
}

.attributeText {
  width: 50vh;
}

.xButton {
  position: absolute;
  right: 2%;
  font-size: 1.5rem;
  color: #ffffff;
  border: none;
  background-color: black;
  cursor: pointer;
}
.discardButton {
  position: relative;
  font-family: 'Poppins', 'sans-serif';
  color: black;
  background-color: white;
  font-weight: 700;
  font-size: 1.5rem;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  border: 2px solid black;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 2rem;
  padding: 8px 20px;
  right: 0px;
  bottom: 0px;
  transition: right 0.3s, bottom 0.3s;
}

.discardButton:hover {
  bottom: 5px;
  right: 5px;
}

.discardButton::after {
  position: absolute;
  content: ' ';
  z-index: -1;
  height: 93%;
  width: 98%;
  border: 2.5px solid #adff00;
  transform: translate3d(5%, 20%, 0px);
  top: 0px;
  left: 0px;
  transition: top 0.3s, left 0.3s;
}

.discardButton:hover::after {
  top: 10px;
  left: 10px;
}

.formContainer .bottomButtons {
  display: flex;
  justify-content: flex-end;
  width: 51vh;
}

::placeholder {
  font-size: 1.3rem;
}
