.container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 5%;
}
.blurb {
  display: flex;
  flex-direction: column;
}

.blurb p {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  max-width: 560px;
  margin-right: 2rem;
  margin-bottom: 0.5rem;
}

.blurb h1 {
  text-align: left;
  font-family: 'Kanit', 'sans-serif';
}

.nftForm {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 820px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width: 300px) and (max-width: 420px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: blue;
  }
}
