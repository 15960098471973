.wrapper {
  display: flex;
  flex-direction: row;
  height: max-content;
  width: 80vw;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.container {
  width: 80%;
  margin: auto;
  height: 100%;
}

.blurb {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 90vh;
  min-height: 50vh;
}

.blurb h1 {
  text-transform: uppercase;
  text-align: left;
  font-family: 'Kanit', 'sans-serif';
  font-weight: 900;
  line-height: 60px;
  margin-bottom: 0.5rem;
  font-size: 4rem;
}

.blurb button {
  margin: 0px;
}

.blurb p {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  max-width: 560px;
  margin-right: 2rem;
  margin-bottom: 0.5rem;
}

.image img {
  height: 40rem;
  width: 40rem;
  margin: 0px;
}

.gallery {
  display: grid;
  height: max-content;
  column-gap: 5px;
  overflow: hidden;
  margin: auto;
  width: 85vw;
}

.column {
  display: flex;
  flex-direction: column;
}
