.container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6rem;
  width: max-content;
  height: 70vh;
  justify-content: space-between;
}

.wrapper {
  display: flex;
  flex-direction: row;
  height: max-content;
  width: 80vw;
  align-items: center;
  justify-content: space-evenly;
}

.blurb {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 90vh;
  min-height: 50vh;
}

.blurb h1 {
  text-transform: uppercase;
  text-align: left;
  font-family: 'Kanit', 'sans-serif';
  font-weight: 900;
  line-height: 60px;
  margin-bottom: 0.5rem;
}

.blurb button {
  margin: 0px;
}

.blurb p {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  max-width: 560px;
  margin-right: 2rem;
  margin-bottom: 0.5rem;
}

.image img {
  height: 20rem;
  width: 30rem;
  margin: 0px;
}

.connect-wallet-button:hover {
  color: black;
  background-color: white;
  border: solid 1.5px yellowgreen;
}
