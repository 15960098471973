.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  height: 100vh;
  width: 100%;
}

.wrapper {
  /* height: 70vh; */
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.wrapper h1 {
  width: 100vh;
  /* height: 35vh; */
  text-align: left;
  font-family: 'Kanit', 'sans-serif';
  padding-left: 7%;
  align-self: start;
}

.wrapper p {
  padding-left: 10%;
  padding-right: 20%;
  padding-top: 1.5%;
  width: 60vw;
}

.wrapper button {
  margin-left: 10%;
}
