.container {
  width: 100%;
  height: 25vh;
  min-height: 100px;
  border: 2px solid black;
  background-color: #f2f2f2;
  margin-top: 25px;
}

.container img {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
}

@media (max-height: 950px) {
  .container {
    margin-top: 20px;
  }
}
