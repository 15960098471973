.button {
  position: relative;
  font-family: 'Poppins', 'sans-serif';
  color: #ffffff;
  background-color: black;
  font-weight: 700;
  font-size: 1.5rem;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 2rem;
  padding: 10px 20px;
  right: 0px;
  bottom: 0px;
  transition: right 0.3s, bottom 0.3s;
}

.button:disabled {
  background-color: #757474;
  pointer-events: none;
}

.button:disabled:hover {
  right: 0px;
  bottom: 0px;
  transition: right 0, bottom 0;
  pointer-events: none;
}

button:disabled:hover::after {
  top: 0;
  left: 0;
}

.button:hover {
  bottom: 5px;
  right: 5px;
}

.button::after {
  position: absolute;
  content: ' ';
  z-index: -1;
  height: 90%;
  width: 98%;
  border: 2.5px solid #adff00;
  transform: translate3d(5%, 20%, 0px);
  top: 0px;
  left: 0px;
  transition: top 0.3s, left 0.3s;
}

button:hover::after {
  top: 10px;
  left: 10px;
}
