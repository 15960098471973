input {
  border: none;
  width: 100%;
  height: 52px;
  background-color: white;
  border: 2px solid black;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 5px;
  margin-right: 15px;
  padding-left: 15px;
  position: relative;
  cursor: auto;
  text-align: left;
}

input:focus {
  outline: none;
}

::placeholder {
  color: black;
}

.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
  width: 100%;
  margin-top: 20px;
}

.formContainer Button {
  height: 52px;
  background-color: rgb(0, 0, 0);
  margin: 0;
  padding-right: 12px;
  padding-left: 12px;
}

.toolTip {
  position: absolute;
  margin-bottom: 175px;
  margin-right: 50px;
  display: flex;
  z-index: 1;
  background-color: #adff00;
  width: 340px;
  height: 80px;
  justify-content: center;
  text-align: left;
  padding-left: 15px;
  padding-right: 20px;
  font-family: 'Poppins';
  flex-direction: column;
  font-size: 15px;
}

.cancelToolTip {
  display: flex;
  justify-content: end;
  cursor: pointer;
  z-index: 2;
  line-height: 0;
  margin-bottom: 10px;
  margin-bottom: 0px;
}

.toolTip ::after {
  content: '';
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 12px solid #adff00;
  z-index: 2;
  position: absolute;
  top: 80px;
  right: 280px;
}

.usernameContainer {
  width: max-content;
  margin-left: 20px;
}

h2 {
  font-size: 48px;
  font-weight: 900;
  font-family: 'Kanit';
}

@media screen and (min-height: 850px) {
  .usernameContainer {
    margin-top: 20px;
  }
}
