.logo {
  font-family: 'Kanit', 'sans-serif';
  color: black;
  font-size: 3rem;
  margin-left: 70px;
  margin-top: 20px;
  width: fit-content;
}

.logo::after {
  position: absolute;
  content: attr(data-text);
  -webkit-text-stroke: 1.5px greenyellow;
  color: white;
  z-index: -1;
  top: 0.1rem;
  left: 0.2rem;
  margin-left: 70px;
  margin-top: 20px;
}

.logo:hover {
  color: white;
  -webkit-text-stroke: 1px black;
  cursor: pointer;
  text-shadow: 2px 2px 0 greenyellow;
}

.logo:hover:after {
  text-shadow: none;
}
