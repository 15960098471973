.list {
  font-family: 'Kanit', sans-serif;
  color: white;
  font-weight: 900;
  font-size: 30px;
  -webkit-text-stroke: 1px black;
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}

.list:hover {
  color: black;
}
