.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
  flex-direction: row;
}

.list {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.image img {
  width: 3rem;
  height: 3rem;
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
}
